import React from 'react';
import './Courses.css'; // Import your CSS file for styling

const Courses = () => {
    return (
        <div>
        <div className="courses-container">
            <div className="courses-background">
                {/* Background Image */}
                <img src="/Students.jpg" alt="Background" className="background-image" />
                {/* Text Overlay */}
                <div className="text-overlay">
                    <h2>Courses Offered</h2>
                    <p>Explore our wide range of courses to enhance your skills.</p>
                </div>
            </div>
            
        </div>
        <section className="courses">
            <h1>Our Subjects <hr className="w-25 mx-auto" /></h1>
            <div className="course">
                <img src="/physics.jpg" alt="Course 1" />
                <h2>Physics</h2>
                <p>Teaching biology effectively necessitates a structured curriculum, interactive lessons with demonstrations, clear explanations, regular assessment, student support, and staying updated with teaching methods.
</p>
            </div>
            <div className="course">
                <img src="/biology.jpg" alt="Course 2" />
                <h2>Biology</h2>
                <p>Teaching biology effectively necessitates a structured curriculum, interactive lessons with demonstrations, clear explanations,regular assessment, student support, and staying updated with teaching methods.</p>
            </div>
            <div className="course">
                <img src="/chemistry.jpeg" alt="Course 3" />
                <h2>Chemistry</h2>
                <p>Teaching chemistry effectively involves planning a structured curriculum, using interactive lessons with demonstrations, providing clear explanations and , assessing regularly.</p>
            </div>
            <div className="course">
                <img src="/math.jpeg" alt="Course 3" />
                <h2>Mathematics</h2>
                <p>Teaching mathematics effectively involves a structured curriculum, interactive lessons with demonstrations, clear explanations, practical problem-solving, regular assessment, student support, and staying updated with teaching methods.</p>
            </div>
            <div className="course">
                <img src="/10th.jpeg" alt="Course 3" />
                <h2>10th bord</h2>
                <p>Teaching 10th-grade subjects effectively requires a structured curriculum, interactive lessons with demonstrations, clear explanations, practical applications, regular assessments, student support, and staying updated with teaching methods specific to each subject.





</p>
            </div>
            <div className="course">
                <img src="/12th img.jpg" alt="Course 3" />
                <h2>12th Bord</h2>
                <p>Teaching 12th-grade subjects effectively involves a structured curriculum, interactive lessons with demonstrations, clear explanations, practical applications, regular assessments, personalized student support, and staying updated with subject-specific teaching methodologies.</p>
            </div>

            
        </section>
     
        

        </div>
    );
}

export default Courses;
