// // import './NavBar.css';
// // import React, { useState, useEffect } from 'react';
// // import { Link } from 'react-router-dom';

// // function NavBar() {
// //     const [scrolled, setScrolled] = useState(false);
// //     const [menuOpen, setMenuOpen] = useState(false);

// //     useEffect(() => {
// //         const handleScroll = () => {
// //             if (window.scrollY > 50) {
// //                 setScrolled(true);
// //             } else {
// //                 setScrolled(false);
// //             }
// //         };

// //         window.addEventListener('scroll', handleScroll);
// //         return () => {
// //             window.removeEventListener('scroll', handleScroll);
// //         };
// //     }, []);

// //     const toggleMenu = () => {
// //         setMenuOpen(!menuOpen);
// //     };

// //     return (
// //         <div>
// //             <header className="contact-header">
// //                 <p className="contact-details">
// //                     Phone: <span className="contact-number">123-456-7890</span> | 
// //                     Email: <a href="mailto:example@example.com" className="contact-email">example@example.com</a>
// //                 </p>
// //             </header>
// //             <div className={`navbar ${scrolled ? 'scrolled' : ''}`} >
// //                 <div className='nav-logo '>
// //                     <img src="/Hariom logo.jpg" alt="LOGO" className="logo" />
// //                     <b className='alt-1'>Hariom Education</b>
// //                 </div>
// //                 <div className='flex nav-links'>
// //                     <Link className='link' to="/">Home</Link>
// //                     <Link className='link' to="/about">About</Link>
// //                     <Link className='link' to="/courses">Courses</Link>
// //                     <Link className='link' to="/contact">Contact</Link>
// //                     <Link className='link' to="/services">Services</Link>
// //                     <Link className='link' to="/login">Login</Link>
// //                 </div>
// //                 <div className="burger" onClick={toggleMenu}>
// //                     <div className={`line1 ${menuOpen ? 'toggle' : ''}`}></div>
// //                     <div className={`line2 ${menuOpen ? 'toggle' : ''}`}></div>
// //                     <div className={`line3 ${menuOpen ? 'toggle' : ''}`}></div>
// //                 </div>
// //                 <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
// //                     <Link className='link' to="/" onClick={toggleMenu}>Home</Link>
// //                     <Link className='link' to="/about" onClick={toggleMenu}>About</Link>
// //                     <Link className='link' to="/courses" onClick={toggleMenu}>Courses</Link>
// //                     <Link className='link' to="/contact" onClick={toggleMenu}>Contact</Link>
// //                     <Link className='link' to="/services" onClick={toggleMenu}>Services</Link>
// //                     <Link className='link' to="/login" onClick={toggleMenu}>Login</Link>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // }

// // export default NavBar;


// import './NavBar.css';
// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// function NavBar() {
//     const [scrolled, setScrolled] = useState(false);
//     const [menuOpen, setMenuOpen] = useState(false);

//     useEffect(() => {
//         const handleScroll = () => {
//             if (window.scrollY > 50) {
//                 setScrolled(true);
//             } else {
//                 setScrolled(false);
//             }
//         };

//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     const toggleMenu = () => {
//         setMenuOpen(!menuOpen);
//     };

//     return (
//         <div>
//             <header className="contact-header">
//                 <p className="contact-details">
//                     Phone: <span className="contact-number">123-456-7890</span> | 
//                     Email: <a href="mailto:example@example.com" className="contact-email">example@example.com</a>
//                 </p>
//             </header>
//             <div className={`navbar ${scrolled ? 'scrolled' : ''}`} >
//                 <div className='nav-logo '>
//                     <img src="/Hariom logo.jpg" alt="LOGO" className="logo" />
//                     <b className='alt-1'>Hariom Education</b>
//                 </div>
//                 <div className='flex nav-links'>
//                     <Link className='link' to="/">Home</Link>
//                     <Link className='link' to="/about">About</Link>
//                     <Link className='link' to="/courses">Courses</Link>
//                     <Link className='link' to="/contact">Contact</Link>
//                     <Link className='link' to="/services">Services</Link>
//                     <Link className='link' to="/login">Login</Link>
//                 </div>
//                 <div className="burger" onClick={toggleMenu}>
//                     <i className={`fas ${menuOpen ? 'fa-time' : 'fa-bars'}`}></i>
//                 </div>
//                 <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
//                     <Link className='link' to="/" onClick={toggleMenu}>Home</Link>
//                     <Link className='link' to="/about" onClick={toggleMenu}>About</Link>
//                     <Link className='link' to="/courses" onClick={toggleMenu}>Courses</Link>
//                     <Link className='link' to="/contact" onClick={toggleMenu}>Contact</Link>
//                     <Link className='link' to="/services" onClick={toggleMenu}>Services</Link>
//                     <Link className='link' to="/login" onClick={toggleMenu}>Login</Link>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default NavBar;
import 'boxicons/css/boxicons.min.css';

import './NavBar.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <header className="contact-header">
                <p className="contact-details">
                    Phone: <span className="contact-number">7367957682</span> | 
                    Email: <a href="mailto:example@example.com" className="contact-email">hariomeducation01@gmail.com</a>
                </p>
            </header>
            <div className={`navbar ${scrolled ? 'scrolled' : ''}`} >
                <div className='nav-logo '>
                    <img src="/Hariom logo.jpg" alt="LOGO" className="logo" />
                    <b className='alt-1'>Hariom Education</b>
                </div>
                <div className='flex nav-links'>
                    <Link className='link' to="/">Home</Link>
                    <Link className='link' to="/about">About</Link>
                    <Link className='link' to="/courses">Courses</Link>
                    <Link className='link' to="/contact">Contact</Link>
                    {/* <Link className='link' to="/services">Services</Link> */}
                    <div className="dropdown">
        <Link className='link' to="#">Services</Link>
        <div className="dropdown-content">
          <Link className='dropdown-link' to="/Services">NEET Services</Link>
          <Link className='dropdown-link' to="/jeeservices">JEE Services</Link>
          <Link className='dropdown-link' to="/bordservices">Board Services</Link>
        </div>
      </div>
                    <Link className='link' to="/login">Login</Link>
                </div>
                <div className="burger" onClick={toggleMenu}>
                    <i className={`bx ${menuOpen ? 'bx-x' : 'bx-menu'}`}></i>
                </div>
                <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
                    <Link className='link' to="/" onClick={toggleMenu}>Home</Link>
                    <Link className='link' to="/about" onClick={toggleMenu}>About</Link>
                    <Link className='link' to="/courses" onClick={toggleMenu}>Courses</Link>
                    <Link className='link' to="/contact" onClick={toggleMenu}>Contact</Link>
                    <Link className='link' to="/services" onClick={toggleMenu}>Services</Link>
                    <Link className='link' to="/login" onClick={toggleMenu}>Login</Link>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
