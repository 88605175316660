import './Home.css';
import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import backgroundImage from './animation.png';
import {  NavLink } from 'react-router-dom'; // Import Link from React Router


// import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import CustomNextArrow from './CustomNextArrow';
import CustomPrevArrow from './CustomPrevArrow';
import { Button } from 'bootstrap';

function Home() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };
  const countStyle ={
      
    backgroundImage: `url(${backgroundImage}) `,
    backgroundSize: 'cover',
    opacity: 1.1,
    
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh', // Full viewport height
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
    color: 'white' // Text color for better visibility
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Ensure it only triggers once
    threshold: 0.1, // Percentage of element in view
});

  return (
    <div>
     

      <Slider {...settings} className="slider">
        <div className="slide">
          <img className="slider-image" src="/animation.png" alt="Scenic view of India" />
          <div className="text-overlay"style={{color:"#333"}}>Hariom Education is Best Institute in Muzaffarpur. 
           <div> <NavLink to="/contact">
             <button> Join Now</button>
            </NavLink>
            </div>
              </div>
        </div>
        <div className="slide">
          <img className="slider-image" src="/eng.jpg" alt="Beautiful landscape in India" />
          <div className="text-overlay"style={{color:" #333"}} >Live Interation With Teacers
          <div> <NavLink to="/contact">
             <button> Join Now</button>
            </NavLink>
            </div>
          </div>
        </div>
        <div className="slide">
          <img className="slider-image" src="/slide3.jpg" alt="Historical monument in India" />
          <div className="text-overlay"style={{color:" #333"}}>Online and Offline both Feature Available
          <div> <NavLink to="/contact">
             <button> Join Now</button>
            </NavLink>
            </div>
          </div>
        </div>
      </Slider>

      <br />
      <hr />
      <div className="container" style={{ backgroundColor: '#333' }}>
        <div className="card">
          <div className="imgBx">
          <NavLink to="/services"> 
              <img src="/doc.jpg" alt="Baked Feta Pasta with Shrimp" />
            </NavLink>
            <h2>For NEET </h2>
            <p>
            NEET (National Eligibility-cum-Entrance Test) is an entrance exam in India for medical aspirants. It's conducted by NTA for admission to MBBS, BDS, and other undergraduate medical courses in approved.
            </p>
          </div>
        </div>

        <br />

        <div className="card">
          <div className="imgBx">
          <NavLink to="/Jeeservices">
              <img src="/eng.jpg" alt="Mango Chicken Rice Paper Rolls" />
            </NavLink>
            <h2> For IIT-JEE </h2>
            <p>
            IIT JEE (Indian Institutes of Technology Joint Entrance Examination) is an exam in India for admission to prestigious engineering institutions like IITs. It tests students on physics, chemistry, and mathematics for undergraduate engineering programs.
            </p>
          </div>
        </div>

        <br />

        <div className="card">
          <div className="imgBx">
          <NavLink to="/Bordservices">
              <img src="/board.jpg" alt="Pink Dragon Fruit Smoothie" />
            </NavLink>
            <h2>For Bord </h2>
            <p>
            Board exams in India refer to standardized tests conducted by state or central boards (like CBSE, ICSE) for secondary (Class 10) and higher secondary (Class 12) students. They assess students' knowledge across subjects for academic certification and higher education eligibility.
             </p>
          </div>
        </div>
      </div>

  {/* course */}

  <section className="courses">
            <h1>Our Subjects <hr className="w-25 mx-auto" /></h1>
            <div className="course">
                <img src="/physics.jpg" alt="Course 1" />
                <h2>Physics</h2>
                <p>Teaching biology effectively necessitates a structured curriculum, interactive lessons with demonstrations, clear explanations, regular assessment, student support, and staying updated with teaching methods.
</p>
            </div>
            <div className="course">
                <img src="/biology.jpg" alt="Course 2" />
                <h2>Biology</h2>
                <p>Teaching biology effectively necessitates a structured curriculum, interactive lessons with demonstrations, clear explanations,regular assessment, student support, and staying updated with teaching methods.</p>
            </div>
            <div className="course">
                <img src="/chemistry.jpeg" alt="Course 3" />
                <h2>Chemistry</h2>
                <p>Teaching chemistry effectively involves planning a structured curriculum, using interactive lessons with demonstrations, providing clear explanations and , assessing regularly.</p>
            </div>
            <div className="course">
                <img src="/math.jpeg" alt="Course 3" />
                <h2>Mathematics</h2>
                <p>Teaching mathematics effectively involves a structured curriculum, interactive lessons with demonstrations, clear explanations, practical problem-solving, regular assessment, student support, and staying updated with teaching methods.</p>
            </div>
            <div className="course">
                <img src="/10th.jpeg" alt="Course 3" />
                <h2>10th bord</h2>
                <p>Teaching 10th-grade subjects effectively requires a structured curriculum, interactive lessons with demonstrations, clear explanations, practical applications, regular assessments, student support, and staying updated with teaching methods specific to each subject.





</p>
            </div>
            <div className="course">
                <img src="/12th img.jpg" alt="Course 3" />
                <h2>12th Bord</h2>
                <p>Teaching 12th-grade subjects effectively involves a structured curriculum, interactive lessons with demonstrations, clear explanations, practical applications, regular assessments, personalized student support, and staying updated with subject-specific teaching methodologies.</p>
            </div>

            
        </section>
      
{/* 
animation */}
   <section style={countStyle} className="home" ref={ref}>
            <div className="overlay" >
                <h1>Welcome to Our Learning Platform</h1>
                <div className="animate-text">
                    <p>Tests: {inView ? <CountUp end={100} /> : '0'}+</p>
                    <p>Doubts: {inView ? <CountUp end={150} /> : '0'}+</p>
                    <p>Assignments: {inView ? <CountUp end={100} /> : '0'}+</p>
                </div>
            </div>
        </section>

      

{/* new section */}

<section className="team">
            <h1> Our <b> Team </b> <hr className="w-25 mx-auto" /> </h1>
            
            <div className="team-member">
                <img src="\hariom1.jpg" alt="Team Member 1" />
                <h2>Hariom Kumar</h2>
                <p>Hariom is a teacher who specializes in preparing students for exams. He has a deep understanding of the subjects he teaches and is skilled at creating effective study plans and explaining complex concepts to help his students succeed.
</p>
            </div>
            <div className="team-member">
                <img src="/hariom1.jpg" alt="Team Member 2" />
                <h2>Hariom Kumar</h2>
                <p>   Hariom is a teacher who specializes in preparing students for exams. He has a deep understanding of the subjects he teaches and is skilled at creating effective study plans and explaining complex concepts to help his students succeed.

                </p>
            </div>
            <div className="team-member">
                <img src="/hariom1.jpg" alt="Team Member 3" />
                <h2>Hariom Kumar</h2>
                <p>Hariom is a teacher who specializes in preparing students for exams. He has a deep understanding of the subjects he teaches and is skilled at creating effective study plans and explaining complex concepts to help his students succeed.
</p>
            </div>

            <div className="team-member">
                <img src="/person.jpeg" alt="Team Member 3" />
                <h2>Mike Johnson</h2>
                <p>Mike is a teacher who specializes in preparing students for IIT-JEE. He has a deep understanding of the subjects he teaches and is skilled at creating effective study plans and explaining complex concepts to help his students succeed in the IIT-JEE exam.</p>
            </div>
            <div className="team-member">
                <img src="/person.jpeg" alt="Team Member 3" />
                <h2>Mike Johnson</h2>
                <p>Mike is a teacher who specializes in preparing students for IIT-JEE. He has a deep understanding of the subjects he teaches and is skilled at creating effective study plans and explaining complex concepts to help his students succeed in the IIT-JEE exam.</p>
            </div>
            <div className="team-member">
                <img src="/person.jpeg" alt="Team Member 3" />
                <h2>Mike Johnson</h2>
                <p>Mike is a teacher who specializes in preparing students for IIT-JEE. He has a deep understanding of the subjects he teaches and is skilled at creating effective study plans and explaining complex concepts to help his students succeed in the IIT-JEE exam.</p>
            </div>
        </section>


      
   
     
      

    </div>
  );
}

export default Home;
