// src/components/CustomNextArrow.js
import React from 'react';
import './Home.css';
import { FaArrowRight } from 'react-icons/fa';

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <FaArrowRight
      className={className}
      style={{ ...style, display: 'block', color: 'black' }}
      onClick={onClick}
    />
  );
};

export default CustomNextArrow;
