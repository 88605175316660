
import './App.css';
import NavBar from  './components/NavBar';
import {BrowserRouter,Routes,Route } from "react-router-dom";
import Home from './components/Home'
import About from './components/About'
import Courses from './components/Courses'
import Contact from './components/Contact'
import Services from './components/Services'
import JeeServices from './components/JeeServices'
import BordServices from './components/BordServices'
import Footer from './components/Footer'

import Dashboard from './components/Dashboard';

import PrivateRoute from './components/PrivateRoute';
  function App() {
  return (
    <>
     <BrowserRouter>
     <NavBar/> 
     <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/courses' element={<Courses/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/services' element={<Services/>} />
      <Route path='/Jeeservices' element={<JeeServices/>} />
      <Route path='/Bordservices' element={<BordServices/>} />
     
      <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      

     </Routes>
     <Footer/>
     
     </BrowserRouter>

    </>
  );
}

export default App;


