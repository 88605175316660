 import './Services.css';
 import React, { useState } from 'react';
function Services(){
  const [showMoreNotes, setShowMoreNotes] = useState(false);
  const [showMoreAssignments, setShowMoreAssignments] = useState(false);

  const toggleNotes = () => {
      setShowMoreNotes(!showMoreNotes);
  };

  const toggleAssignments = () => {
      setShowMoreAssignments(!showMoreAssignments);
  };
    return (
<div> 


      
      <section id="service" >
        <div className="container my-5 py-5" >
          <div className="row">
            <div className="col-12">
              <h3 className="fs-5 text-center mb-0">Our Services</h3>
              <h1 className="display-6 text-center mb-4">
                Our <b>Awesome</b> Services
              </h1>
             <hr className=" w-25 mx-auto " />
            </div>
          </div>

          <div className="row mt-5 sub-container">
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-cogs fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold" >Highly Customizable</h5>
                  <p className="card-text lead">
                  Our online classes offer real-time engagement with expert instructors and peers, promoting collaborative learning and instant feedback for a deeply immersive study experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-mobile fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">doubt discussion</h5>
                  <p className="card-text lead">
                  We offer doubt discussion sessions, providing a platform for students to clarify questions and deepen their understanding with expert guidance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-users fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Live Interation</h5>
                  <p className="card-text lead">
                  Our live interaction feature offers real-time engagement with expert instructors and peers, enhancing collaborative learning and instant feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 sub-container" >
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-laptop fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Animated PDF</h5>
                  <p className="card-text lead">
                  Our animated PDFs provide an easy-to-learn experience, combining visuals and interactivity to enhance understanding and engagement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-file-code-o fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Notes & Assignment</h5>
                  <p className="card-text lead">
                  We provide comprehensive notes and assignments to support your learning journey, ensuring thorough understanding and practical application of course materials.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-star-half-o fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Excellent Result</h5>
                  <p className="card-text lead">
                  Our approach ensures excellent results through dedicated support, quality resources, and tailored learning experiences to maximize your success.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>



      {/* Notes and Assignments */}
      <div className="services-container">
            <div className="services-section">
                <h2>Notes</h2>
                <p>Services offered in the Notes section.</p>
                {/* Download Buttons for Notes */}
                <div className="download-buttons">
                    <a href="/Notes/nitin passbook.pdf" download="NotesFile1" className="download-button">Download Notes 1</a>
                    <a href="/path/to/notes/file2.pdf" download="NotesFile2" className="download-button">Download Notes 2</a>
                    {showMoreNotes && (
                        <>
                            <a href="/path/to/notes/file3.pdf" download="NotesFile3" className="download-button">Download Notes 3</a>
                            <a href="/path/to/notes/file4.pdf" download="NotesFile4" className="download-button">Download Notes 4</a>
                            <a href="/path/to/notes/file5.pdf" download="NotesFile5" className="download-button">Download Notes 5</a>
                        </>
                    )}
                </div>
                {/* Show More Button for Notes */}
                {!showMoreNotes && (
                    <button className="show-more-button" onClick={toggleNotes}>
                        Show More Notes
                    </button>
                )}
            </div>
            <div className="services-section">
                <h2>Assignments</h2>
                <p>Services offered in the Assignments section.</p>
                {/* Download Buttons for Assignments */}
                <div className="download-buttons">
                    <a href="/path/to/assignments/file1.pdf" download="AssignmentsFile1" className="download-button">Download Assignment 1</a>
                    <a href="/path/to/assignments/file2.pdf" download="AssignmentsFile2" className="download-button">Download Assignment 2</a>
                    {showMoreAssignments && (
                        <>
                            <a href="/path/to/assignments/file3.pdf" download="AssignmentsFile3" className="download-button">Download Assignment 3</a>
                            <a href="/path/to/assignments/file4.pdf" download="AssignmentsFile4" className="download-button">Download Assignment 4</a>
                            <a href="/path/to/assignments/file5.pdf" download="AssignmentsFile5" className="download-button">Download Assignment 5</a>
                        </>
                    )}
                </div>
                {/* Show More Button for Assignments */}
                {!showMoreAssignments && (
                    <button className="show-more-button" onClick={toggleAssignments}>
                        Show More Assignments
                    </button>
                )}
            </div>
        </div>
</div>

 );
}
export  default Services