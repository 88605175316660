import React from "react";
import './Home.css';
import { NavLink } from "react-router-dom";

function Services() {
    return (
        <div>
            <footer className="footer text-white">
                <div className="container">
                    <footer className="py-5">
                        <div className="d-flex justify-content-center pt-4 mt-4 border-top">
                            <ul className="list-unstyled d-flex">
                                <li className="ms-3">
                                    <NavLink className="link-light social-icon" to="#">
                                        <i className="fa fa-facebook fa-2x"></i>
                                    </NavLink>
                                </li>
                                <li className="ms-3">
                                    <NavLink className="link-light social-icon" to="#">
                                        <i className="fa fa-instagram fa-2x"></i>
                                    </NavLink>
                                </li>
                                <li className="ms-3">
                                    <NavLink className="link-light social-icon" to="#">
                                        <i className="fa fa-youtube fa-2x"></i>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <p className="text-center">© 2024 | Hariom Education | All Rights Reserved. Developed By Hariom Education Software Team</p>
                    </footer>
                </div>
            </footer>
        </div>
    );
}

export default Services;
