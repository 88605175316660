import './Home.css'
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import CountUp from 'react-countup';
 import { useInView } from 'react-intersection-observer';
import backgroundImage from './animation.png';

function About(){
    const countStyle ={
      
        backgroundImage: `url(${backgroundImage}) `,
        backgroundSize: 'cover',
        opacity: 1.1,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100vh', 
        // width:'100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        color: 'white' // Text color for better visibility
      }
      const { ref, inView } = useInView({
        triggerOnce: true, // Ensure it only triggers once
        threshold: 0.1, // Percentage of element in view
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [students] = useState([
        {
            imageUrl: '/unknown-std.jpg', // Replace with the correct path to the image
            name: 'John Doe',
            rollNumber: '101',
            performance: 'Excellent',
            result: 'Passed',
            additionalText: 'John has shown great improvement this semester.'
        },
        {
            imageUrl: '/unknown-std.jpg' ,// Replace with the correct path to the image
            name: 'Jane Smith',
            rollNumber: '102',
            performance: 'Good',
            result: 'Passed',
            additionalText: 'Jane has consistently performed well in all subjects.'
        },
        {
            imageUrl: '/unknown-std.jpg', // Replace with the correct path to the image
            name: 'Sam Brown',
            rollNumber: '103',
            performance: 'Average',
            result: 'Passed',
            additionalText: 'Sam needs to focus more on math and science.'
        },

        {
            imageUrl: '/unknown-std.jpg', // Replace with the correct path to the image
            name: 'John Doe',
            rollNumber: '101',
            performance: 'Excellent',
            result: 'Passed',
            additionalText: 'John has shown great improvement this semester.'
        },
        {
            imageUrl: '/unknown-std.jpg' ,// Replace with the correct path to the image
            name: 'Jane Smith',
            rollNumber: '102',
            performance: 'Good',
            result: 'Passed',
            additionalText: 'Jane has consistently performed well in all subjects.'
        },
        {
            imageUrl: '/unknown-std.jpg', // Replace with the correct path to the image
            name: 'John Doe',
            rollNumber: '101',
            performance: 'Excellent',
            result: 'Passed',
            additionalText: 'John has shown great improvement this semester.'
        },
        {
            imageUrl: '/unknown-std.jpg' ,// Replace with the correct path to the image
            name: 'Jane Smith',
            rollNumber: '102',
            performance: 'Good',
            result: 'Passed',
            additionalText: 'Jane has consistently performed well in all subjects.'
        },

        {
            imageUrl: '/unknown-std.jpg', // Replace with the correct path to the image
            name: 'John Doe',
            rollNumber: '101',
            performance: 'Excellent',
            result: 'Passed',
            additionalText: 'John has shown great improvement this semester.'
        },
        {
            imageUrl: '/unknown-std.jpg' ,// Replace with the correct path to the image
            name: 'Jane Smith',
            rollNumber: '102',
            performance: 'Good',
            result: 'Passed',
            additionalText: 'Jane has consistently performed well in all subjects.'
        },
        // Add more students as needed
    ]);

    const [displayCount, setDisplayCount] = useState(3);

    const handleShowMore = () => {
        setDisplayCount(displayCount + 3); // Show 3 more students when the button is clicked
    };

    //    const handleShowLess = () => {
    //     setDisplayCount(3); // Show only the initial 3 students when the button is clicked
    // };

    const filteredStudents = students.filter(
        (student) =>
            student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            student.rollNumber.includes(searchQuery)
    );
    return (

        <div>

         {/* 
animation */}
   <section style={countStyle} className="home" ref={ref}>
            <div className="overlay">
                <h1>Welcome to Our Learning Platform</h1>
                <div className="animate-text">
                    <p>Tests: {inView ? <CountUp end={100} /> : '0'}+</p>
                    <p>Doubts: {inView ? <CountUp end={150} /> : '0'}+</p>
                    <p>Assignments: {inView ? <CountUp end={100} /> : '0'}+</p>
                </div>
            </div>
        </section>

{/* 
      student section */}
  <div className="about">
            <h2>Student Section</h2>
            <input
                type="text"
                placeholder="Search by name or roll number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
            />
            <div className="student-list">
                {filteredStudents.map((student, index) => (
                    <div key={index} className="student-container">
                    <img src={student.imageUrl} alt={student.name} className="student-image" />

                        <h3>{student.name}</h3>
                        <p>Roll Number: {student.rollNumber}</p>
                        <p>Performance: {student.performance}</p>
                        <p>Result: {student.result}</p>
                        <p>{student.additionalText}</p>
                    </div>
                ))}
            </div>

            {displayCount < filteredStudents.length && (
                <button onClick={handleShowMore} className="show-more-button">
                    Show More
                </button>
            )}

        </div>

      
      



        <section id="about" className="pb-5">
            <div className="container my-5 py-5">
                <div className="row">
                    <div className="col-md-6">
                        <img src="/Students.jpg" alt="About" className="w-75 mt-5" />
                    </div>
                    <div className="col-md-6">
                        <h3 className="fs-5 mb-0">About Us</h3>
                        <h1 className="display-6 mb-2">Who <b>We</b> Are</h1>
                        <hr className="w-50"/>
                        <p className="lead mb-4">Welcome to Hariom Education – your gateway to lifelong learning! At Hariom Education, we are dedicated to providing high-quality, accessible, and engaging educational experiences that empower students to achieve their personal and professional goals. Our expert instructors, diverse course offerings, and flexible learning options ensure that you can learn at your own pace, anytime, anywhere. Join our vibrant community of learners from around the globe, engage with interactive content, and earn recognized certificates that will enhance your resume and showcase your skills. Founded in [Year], Hariom Education was created to bridge the gap between traditional learning and the digital age, making top-notch education accessible to everyone. Start your journey with us today and unlock your full potential!</p>
                        <NavLink to="/contact" className="btn btn-primary rounded-pill px-4 py-2">Get Started</NavLink> 
                        <NavLink to="/contact" className= "btn btn-outline-primary rounded-pill px-4 py-2 ms-2">Contact Us</NavLink>
                    </div>
                </div>
            </div>
        </section>
    </div>

 )
}
export  default About